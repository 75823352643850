import React from 'react';
import Styles from './Projects.module.scss';
import Title from '../../Shared/Title/Title';
import SectionText from '../../Shared/SectionText/SectionText';
import ProjectList from '../../ProjectList/ProjectList';

export default function Projects() {
  return (
    <div className={Styles.projectsSection}>
      <Title text="Personal projects" underlineColor="#6c63ff" />

      <SectionText text="During some of my free time apart from my full-time position, I like to work on building my personal projects. Listed below, you can find my recent project." />

      <ProjectList />
    </div>
  );
}
