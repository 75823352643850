import React from 'react';
import Styles from './SkillsList.module.scss';
import SkillsCard from './SkillsCard/SkillsCard';
import information from '../../data/information.json';

export default function SkillsList() {
  const { skills } = information;

  return (
    <div className={Styles.skillsListContainer}>
      <SkillsCard
        img="frontend"
        title="Frontend development"
        desc="I build interactive, responsive and scalable user interfaces using modern web-technologies and tools such as Javascript/Typescript, React.js, Styled-components, Jest.js and Cypress."
        skillsArr={skills.frontend}
      />

      <SkillsCard
        img="server"
        title="Backend development"
        desc="I use javascript on the server-side by utilizing its run-time node.js. Also, I have experience in interacting with the NoSQL database MongoDB. As well as using backends as a service such as Google Firebase."
        skillsArr={skills.backend}
      />
    </div>
  );
}
