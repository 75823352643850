import React from 'react';

import Styles from './TechnologyItem.module.scss';

export default function TechnologyItem({ icon, name }) {
  return (
    <div className={Styles.wrapper}>
      <img
        className={Styles.icon}
        src={require(`../../../../../assets/img/icons/${icon}`)}
        alt=""
      />
      <p className={Styles.text}>{name}</p>
    </div>
  );
}
