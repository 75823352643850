import React from 'react';
import Styles from './NavbarItems.module.scss';
import IconButton from '../../../Shared/Buttons/IconButton/IconButton';
import phoneWhite from '../../../../assets/img/icons/phone-white.svg';
import { Link } from 'react-scroll';

export default function NavbarItems({ open, setOpen }) {
  const handleOnclick = () => {
    setOpen(false);
  };

  return (
    <ul id={open ? Styles.active : ''} className={Styles.itemsContainer}>
      <Link to="whatIoffer" smooth={true} offset={50} duration={500}>
        <li onClick={handleOnclick} className={Styles.item}>
          What I offer
        </li>
      </Link>

      <Link to="experience" smooth={true} offset={50} duration={500}>
        <li onClick={handleOnclick} className={Styles.item}>
          Experience
        </li>
      </Link>

      <Link to="projects" smooth={true} offset={50} duration={500}>
        <li onClick={handleOnclick} className={Styles.item}>
          Personal projects
        </li>
      </Link>

      <Link to="feedback" smooth={true} offset={100} duration={500}>
        <li onClick={handleOnclick} className={Styles.item}>
          Feedback
        </li>
      </Link>

      <Link to="contact" smooth={true} offset={50} duration={500}>
        <IconButton
          onClick={handleOnclick}
          filled
          text="Contact"
          icon={phoneWhite}
        />
      </Link>
    </ul>
  );
}
