import React from 'react';
import Styles from './Contact.module.scss';
import smilingFace from '../../../assets/img/icons/smily.svg';
import email from '../../../assets/img/icons/email.svg';
import phone from '../../../assets/img/icons/phone.svg';

export default function Contact() {
	return (
		<div className={Styles.contactSection}>
			<div className={Styles.textContainer}>
				<h2>Need help with an interesting project?</h2>

				<h2>
					Let’s get in touch! <img src={smilingFace} alt='smiling face' />
				</h2>
			</div>

			<div className={Styles.conatctFields}>
				<div className={Styles.field}>
					<img src={email} alt='Email icon' />

					<a
						rel='noopener noreferrer'
						target='_blank'
						href='mailto:contact@youssef.fi'>
						contact@youssef.fi
					</a>
				</div>

				<div className={Styles.field}>
					<img src={phone} alt='Email icon' />

					<a
						rel='noopener noreferrer'
						target='_blank'
						href='tel:+3584578779771'>
						045 7877 9771
					</a>
				</div>
			</div>
		</div>
	);
}
