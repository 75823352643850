import React from 'react';
import Styles from './TimelineItem.module.scss';
import { calcDateDiff } from '../../../utils/portfolio';

export default function TimelineItem({
  dateString,
  img,
  extension,
  company,
  position,
  duration,
  description,
  hasEnded
}) {
  const pastDateArr = dateString.split(','); // MMM, DD, YYYY
  const today = new Date();

  const past = new Date(
    `${pastDateArr[2]}, ${pastDateArr[0]}, ${pastDateArr[1]}`
  );

  const { months, years } = calcDateDiff(today, past);

  const monthString = months > 1 ? 'months' : 'month';
  const yearString = years > 1 ? 'years' : 'year';

  const approxYearsString = years + 1 > 1 ? 'years' : 'year';
  const approxDurationYears = `∼ ${years + 1} ${approxYearsString}`;

  const customDuration =
    months >= 10
      ? approxDurationYears
      : `${years ? `${years} ${yearString}, ` : ''}${
          months ? `${months} ${monthString}` : ''
        }`;

  return (
    <div className={Styles.timelineItemContainer}>
      <p className={Styles.date}>{dateString}</p>

      <div className={Styles.timelineItem}>
        <div className={Styles.imageContainer}>
          <img
            className={Styles.image}
            src={require(`../../../assets/img/companies/${img}${extension}`)}
            alt=""
          />
        </div>

        <div className={Styles.content}>
          <h1 className={Styles.header}>
            {company} - {position}
            {!hasEnded && (
              <p className={Styles.onGoingText}> ( Current position ) </p>
            )}
          </h1>

          <p className={Styles.duration}>{duration || customDuration}</p>

          <p className={Styles.description}>{description}</p>
        </div>

        <div className={Styles.endCircle} />
      </div>
    </div>
  );
}
