import React from 'react';
import Styles from './Burger.module.scss';

export default function Burger({ open, setOpen }) {
	return (
		<div onClick={() => setOpen(!open)}>
			<span className={open ? Styles.active : ''} id={Styles.burgerContainer}>
				<span></span>
			</span>
		</div>
	);
}
