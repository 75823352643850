import React from 'react';
import Styles from './Skills.module.scss';
import Title from '../../Shared/Title/Title';
import SectionText from '../../Shared/SectionText/SectionText';
import SkillsList from '../../SkillsList/SkillsList';

export default function Skills() {
  return (
    <div className={Styles.skillsContainer}>
      <Title underlineColor="var(--color-light-blue)" text="What I offer" />

      <SectionText text="Through consistent daily practice, I’ve managed to develop a skill set that covers both ends of the spectrum, from the front-end to the backend. Currently, front-end development is my stronger side." />

      <SkillsList />
    </div>
  );
}
