import React from 'react';
import Styles from './Title.module.scss';

export default function Title({ text, underlineColor }) {
	return (
		<div className={Styles.titleContainer}>
			<h2 className={Styles.title}>{text}</h2>

			<div
				style={{ backgroundColor: underlineColor }}
				className={Styles.underline}></div>
		</div>
	);
}
