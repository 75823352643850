import React from 'react';
import Styles from './ProjectList.module.scss';
import ProjectCard from './ProjectCard/ProjectCard';
import info from '../../data/information.json';

export default function ProjectList() {
  const { projects } = info;

  return (
    <div className={Styles.projectListContainer}>
      {projects.map((project, index) => {
        const { title, img, desc, stack, demoLink, repo, color, technologies } =
          project;

        return (
          <ProjectCard
            key={index}
            title={title}
            img={img}
            desc={desc}
            stack={stack}
            demoLink={demoLink}
            repo={repo}
            color={color}
            technologies={technologies}
          />
        );
      })}
    </div>
  );
}
