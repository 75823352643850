import React from 'react';
import Styles from './Header.module.scss';
import Navbar from '../../Layout/Navbar/Navbar';
import Button from '../../Shared/Buttons/Button/Button';
import Hero from '../../Layout/Hero/Hero';
import { Link } from 'react-scroll';

export default function Header() {
	return (
		<div className={Styles.headerContainer}>
			<Navbar />

			<div className={Styles.contentRow}>
				<p className={Styles.intoText}>
					Hello, I’m Youssef, a full-stack developer.
				</p>

				<h1 className={Styles.whatIdo}>I build responsive & scalable</h1>
				<h1 className={Styles.whatIdo}>web-based applications.</h1>

				<div className={Styles.callToActionRow}>
					<Link to='contact' smooth={true} offset={50} duration={500}>
						<Button filled text='Get in touch' />
					</Link>

					<Link to='whatIoffer' smooth={true} offset={50} duration={500}>
						<Button text='What I offer' />
					</Link>
				</div>

				<Hero />
			</div>
		</div>
	);
}
