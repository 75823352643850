import React from 'react';
import './App.scss';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-147344431-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
	return (
		<div>
			<PortfolioPage />
		</div>
	);
}

export default App;
