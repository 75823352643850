import React from 'react';
import Styles from './FeedbackCard.module.scss';
import quote from '../../../assets/img/icons/quote.svg';

export default function FeedbackCard({
  text,
  image,
  name,
  position,
  date,
  linkedinLink
}) {
  return (
    <div className={Styles.feedbackCard}>
      <img className={Styles.quote} src={quote} alt="left quote icon" />

      <p className={Styles.date}>{date}</p>

      <p className={Styles.text}>{text}</p>

      <div className={Styles.bottomRow}>
        <img
          className={Styles.colleagueImage}
          src={require(`../../../assets/img/colleagues/${image}`)}
          alt="colleague"
        />

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={linkedinLink}
          className={Styles.name}
        >
          {name}
        </a>

        <span className={Styles.position}>- {position}</span>
      </div>
    </div>
  );
}
