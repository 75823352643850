import React from 'react';
import Styles from './Footer.module.scss';
import Logo from '../../Shared/Logo/Logo';
import linkedin from '../../../assets/img/icons/linkedin.svg';
import github from '../../../assets/img/icons/github.svg';

export default function Footer() {
  return (
    <div className={Styles.footerContainer}>
      <div className={Styles.signature}>
        <span>Custom designed & developed by:</span>
        <Logo />
      </div>

      <div className={Styles.linkIcons}>
        <a
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/youssef-mansour-760b27157/"
          target="_blank"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>

        <a
          rel="noopener noreferrer"
          href="https://github.com/mansouryoussef"
          target="_blank"
        >
          <img src={github} alt="Github" />
        </a>
      </div>
    </div>
  );
}
