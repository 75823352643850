import React from 'react';
import Styles from './SectionText.module.scss';

export default function SectionText({ text }) {
	return (
		<div className={Styles.sectionTextContainer}>
			<p>{text}</p>
		</div>
	);
}
