export const calcDateDiff = (today, past) => {
  const diff = Math.floor(today.getTime() - past.getTime());
  const day = 1000 * 60 * 60 * 24;

  const days = Math.floor(diff / day);
  const months = Math.floor(days / 31);
  const years = Math.floor(months / 12);

  return { months: months - 11 * years, years };
};
