import React from 'react';
import Styles from './FeedbackList.module.scss';
import FeedbackCard from './FeedbackCard/FeedbackCard';
import info from '../../data/information.json';

export default function FeedbackList() {
  const { feedback } = info;

  return (
    <div className={Styles.feedbackList}>
      {feedback.map((feedback, index) => {
        const { name, position, image, text, date, linkedinLink } = feedback;

        return (
          <FeedbackCard
            key={index}
            text={text}
            name={name}
            position={position}
            image={image}
            date={date}
            linkedinLink={linkedinLink}
          />
        );
      })}
    </div>
  );
}
