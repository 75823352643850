import React from 'react';
import Styles from './Feedback.module.scss';
import RotateWrapper from '../../Layout/RotateWrapper/RotateWrapper';
import FeedbackList from '../../FeedbackList/FeedbackList';
import Title from '../../Shared/Title/Title';
import SectionText from '../../Shared/SectionText/SectionText';

export default function Feedback() {
  return (
    <RotateWrapper color="var(--color-green)">
      <Title text="Feedback" underlineColor="var(--color-dark-blue)" />

      <SectionText text="I believe feedback is a huge aspect to growth, regardless if it’s positive or negative, I try to always learn from it and use it as motivation to continue." />

      <FeedbackList />

      <p className={Styles.note}>
        All recommendations can be found on my{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/youssef-mansour-760b27157/"
        >
          linkedin
        </a>{' '}
        profile's recommendation section.
      </p>
    </RotateWrapper>
  );
}
