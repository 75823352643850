import React from 'react';
import Styles from './TechnologiesList.module.scss';

import TechnologyItem from './TechnologyItem/TechnologyItem';

export default function TechnologiesList({ technologies }) {
  return (
    <div className={Styles.wrapper}>
      {technologies.map(technology => {
        const { name, icon } = technology;

        return <TechnologyItem name={name} icon={icon} />;
      })}
    </div>
  );
}
