import React from 'react';
import Styles from './SkillsCard.module.scss';

export default function SkillsCard({ img, title, desc, skillsArr }) {
  const half = Math.ceil(skillsArr.length / 2);

  const firstHalf = skillsArr.slice(0, half);
  const secondHalf = skillsArr.slice(-half);
  return (
    <div className={Styles.skillsCardContainer}>
      <img
        className={Styles.cardImage}
        src={require(`../../../assets/img/${img}.svg`)}
        alt="Card icon"
      />

      <h3 className={Styles.title}>{title}</h3>

      <p className={Styles.desc}>{desc}</p>

      <div className={Styles.skillItemsContainer}>
        {firstHalf.map((skill, index) => (
          <div className={Styles.skillItemContainer} key={index}>
            <img
              src={require(`../../../assets/img/icons/${skill.img}${skill.extension}`)}
              alt="Skill icon"
              className={Styles.skillItemImage}
            />

            <p className={Styles.name}>{skill.name}</p>
          </div>
        ))}
      </div>

      <div className={Styles.skillItemsContainer}>
        {secondHalf.map((skill, index) => (
          <div className={Styles.skillItemContainer} key={index}>
            <img
              src={require(`../../../assets/img/icons/${skill.img}${skill.extension}`)}
              alt="Skill icon"
              className={Styles.skillItemImage}
            />

            <p className={Styles.name}>{skill.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
