import React from 'react';
import Styles from './hero.module.scss';
import hero from '../../../assets/img/hero.svg';
import codeWindow from '../../../assets/img/code-window.svg';
import blueScreen from '../../../assets/img/blue-screen.svg';

export default function Hero() {
	return (
		<div className={Styles.heroContainer}>
			<img src={codeWindow} alt='Code' className={Styles.codeWindow} />
			<img src={blueScreen} className={Styles.blueScreen} alt='Blue screen' />

			<img src={hero} className={Styles.screen} alt='Hero' />
		</div>
	);
}
