import React from 'react';
import Styles from './PortfolioPage.module.scss';
import Header from '../../components/Sections/Header/Header';
import Skills from '../../components/Sections/Skills/Skills';
import Experience from '../../components/Sections/Experience/Experience';
import Projects from '../../components/Sections/Projects/Projects';
import Feedback from '../../components/Sections/Feedback/Feedback';
import Contact from '../../components/Sections/Contact/Contact';
import Footer from '../../components/Sections/Footer/Footer';
import { Element } from 'react-scroll';

export default function PortfolioPage() {
  return (
    <div className={Styles.portfoliopagepage}>
      <Header />

      <Element name="whatIoffer">
        <Skills />
      </Element>

      <Element name="experience">
        <Experience />
      </Element>

      <Element name="projects">
        <Projects />
      </Element>

      <Element name="feedback">
        <Feedback />
      </Element>

      <Element name="contact">
        <Contact />
      </Element>

      <Footer />
    </div>
  );
}
