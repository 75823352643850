import React from 'react';
import Styles from './Timeline.module.scss';
import TimelineItem from './TimelineItem/TimelineItem';
import info from '../../data/information.json';

export default function Timeline() {
  const { experience } = info;

  return (
    <div className={Styles.timeline}>
      {experience.map(item => {
        const {
          dateString,
          img,
          extension,
          company,
          position,
          duration,
          description,
          hasEnded
        } = item;

        return (
          <TimelineItem
            key={img + extension}
            dateString={dateString}
            img={img}
            extension={extension}
            company={company}
            position={position}
            duration={duration}
            description={description}
            hasEnded={hasEnded}
          />
        );
      })}
    </div>
  );
}
