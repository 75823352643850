import React, { useState, useEffect } from 'react';
import Styles from './Navbar.module.scss';
import ImageLogo from '../../Shared/ImageLogo/ImageLogo';
import NavbarItems from './NavbarItems/NavbarItems';
import Burger from './Burger/Burger';

export default function Navbar() {
  const [open, setOpen] = useState(false);

  const [pos, setPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [isOnTop, setIsOnTop] = useState(window.pageYOffset === 0);

  useEffect(() => {
    const handleScroll = () => {
      const YOffset = window.pageYOffset;

      setIsOnTop(YOffset === 0);
      setVisible(pos > YOffset);
      setPos(YOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div
      id={
        visible && !open
          ? Styles.slideDown
          : !visible && !open
          ? Styles.slideUp
          : ''
      }
      className={isOnTop ? Styles.navbarContainer : Styles.isActive}
    >
      <ImageLogo />

      <Burger open={open} setOpen={setOpen} />

      <NavbarItems open={open} setOpen={setOpen} />
    </div>
  );
}
