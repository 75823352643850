import React from 'react';
import Styles from './ProjectCard.module.scss';

import githubIcon from '../../../assets/img/icons/github.svg';
import linkIcon from '../../../assets/img/icons/link.svg';

import TechnologiesList from './TechnologiesList/TechnologiesList';

export default function ProjectCard({
  img,
  title,
  desc,
  stack,
  demoLink,
  repo,
  color,
  technologies
}) {
  return (
    <div
      style={{ borderBottomColor: color }}
      className={Styles.projectCardContainer}
    >
      <img
        className={Styles.projectLogo}
        src={require(`../../../assets/img/projects/${img}.svg`)}
        alt="Project's logo"
      />
      <p className={Styles.title}>{title}</p>
      <p className={Styles.desc}>{desc}</p>

      <TechnologiesList technologies={technologies} />

      <div className={Styles.stackLinksRow}>
        <p style={{ color: color }} className={Styles.stack}>
          {stack}
        </p>

        <span className={Styles.linksContainer}>
          {repo && (
            <a rel="noopener noreferrer" target="_blank" href={repo}>
              <img src={githubIcon} alt="Github" />
            </a>
          )}

          <a
            rel="noopener noreferrer"
            target="_blank"
            className="Link"
            href={demoLink}
          >
            <img className={Styles.linkIcon} src={linkIcon} alt="link" />
            Live app
          </a>
        </span>
      </div>
    </div>
  );
}
