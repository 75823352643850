import React from 'react';
import Styles from './IconButton.module.scss';

export default function IconButton({ text, filled, icon, onClick }) {
	return (
		<button
			onClick={onClick}
			id={filled ? Styles.filled : ''}
			className={Styles.iconButton}>
			<img src={icon} alt='icon' />

			{text}
		</button>
	);
}
