import React from 'react';
import Styles from './Experience.module.scss';
import Title from '../../Shared/Title/Title';
import SectionText from '../../Shared/SectionText/SectionText';
import Timeline from '../../../components/TImeline/Timeline';

export default function Experience() {
  return (
    <div className={Styles.experienceSection}>
      <Title text="Experience" underlineColor="#6c63ff" />

      <SectionText text="Started my coding journey in 2018 where I learned how to code through daily practice, mentorship and online resources. Since then, I have also developed in my professional career by working with the following companies:" />

      <Timeline />
    </div>
  );
}
