import React from 'react';
import Styles from './RotateWrapper.module.scss';

export default function RotateWrapper({ children, color }) {
	return (
		<div className={Styles.wrapperContainer}>
			<div
				style={{ borderColor: ` transparent ${color} transparent transparent` }}
				className={Styles.top}></div>

			<div style={{ backgroundColor: color }} className={Styles.section}>
				{children}
			</div>

			<div
				style={{ borderColor: `${color} transparent transparent transparent` }}
				className={Styles.bottom}></div>
		</div>
	);
}
